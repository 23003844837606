<template>
<div>

  <v-card elevation="0"   style="padding: 15px 30px;height: 100%;" >
  </v-card>

  <vcl-facebook  style="margin: 20px" v-if="!pageloading"></vcl-facebook>
  <v-card v-else elevation="0" style="padding: 15px 30px;height: 100%;" >
    <v-row v-if="$store.getters.auth.loggedIn">
      <v-col cols="6" color="border-bottom: solid 1px #ccc">
        <router-link to="/profile" class="rlink"><v-icon >mdi-close</v-icon><span>برگشت به قبل</span></router-link>
      </v-col>
      <v-col cols="2">

      </v-col>
      <v-col cols="4">

        <p @click="dialog3 = true" class="mb-0 mt-0" >
          <v-icon>mdi-logout-variant</v-icon>&nbsp;
          <span>خروج</span>
        </p>
      </v-col>


      <v-card
          class="mx-auto"
          style="width: 100%;text-align: justify-all;"
      >
        <v-card-subtitle style="color:#0e0e0e;background-color:#2077d3; font-weight: bolder;text-align: justify-all;width: 100%;">
          <span color="red" >ثبت اطلاعات ووچر شما</span>
        </v-card-subtitle>

        <v-card-actions >

          <v-spacer></v-spacer>
          <div  style="justify-content: space-between;flex-direction: column;font-weight: bold;text-align: justify "  >
            <span class="font-medium"> شما ابتدا باید در سایتهایی که ووچر پرفکت مانی ارائه میدهند ثبت نام نمایید و از آنها به مقدار نیاز ووچر پرفکت مانی تهیه نمایید </span> <br>
            <br>
            <span class="font-medium">دقت داشته باشید بعد از دریافت ووچر اطلاعات مربوطه را در کادرهای زیر وارد نمایید و منتظر باشید تا تایید مبلغ در سایت برای شما انجم شود. </span> <br>
            <br>

            <span class="font-medium"> برای ثبت نام در یکی از سایتهای ارائه دهنده پرفکت مانی بر روی لینک زیر کلیک کنید </span> <br>
            <br>
            <span class="font-medium"> بعد از ثبت نام باید مراحل احراز هویت را طبق مراحل سایت انجام دهید تا اجازه خرید و فروش را به شما بدهند. خرید از سایت زیر اجباری نیست و شما میتوانید ووچر پرفکت مانی را از هر سایت که تمایل دارید تهیه کنید </span> <br>
            <br>

            <a href="#" target="_blank" style=" text-decoration:none; ">
              برای ثبت نام و دریافت ووچر پرفکت مانی به مدیر پیام دهید</a>

            <br><br>
            <span class="font-medium"> با هر پرفکت مانی که ووچر آن را در کاربریتان فعال میکنید مبلغ <span style="color:red">{{  helperNumberFormat(usdprice)  }}</span> تومان برای شما شارژ میشود</span> <br>
            <br>
            <span class="font-medium"> دقت نمایید از ووچرهای استفاده شده در کادر زیر وارد نکنید چون بعد از این کار کاربریتان حذف شده و قابل برگشت نیست برای همین سعی کنید کدها را از جایی که دارید در کادرهای زیر درست کپی کنید</span> <br>
            <br>
            <v-text-field
                style="font-size: small;color:#248d29;text-align:center;"
                placeholder="شماره ووچر"
                v-model="vocher"
            ></v-text-field>
            <v-text-field
                style="font-size: small;color:#248d29;text-align:center;"
                placeholder="کد فعال سازی"
                v-model="activecode"
            ></v-text-field><br>
            <span class="font-medium" style="color:#b51269" v-if="1==0"> در این مرحله فیلترشکن را روشن کنید و بر روی کلید زیر بزنید</span> <br>
            <v-btn  @click="insertTodaraee(vocher,activecode)" :loading="loading" v-if="btnperfect1" depressed color="#e3a232" height="50" style="width: 100%"> تایید و ثبت در سرور ما </v-btn>
            <v-btn  @click="sendtodaraee(user,pass,idper)" :loading="loading" v-if="1==0" depressed color="#4287f5" height="50" style="width: 100%"> تایید از سایت پرفکت مانی و ثبت در برنامه </v-btn>
          </div>

        </v-card-actions>

      </v-card>




    </v-row>
  </v-card>

  <vcl-facebook style="margin: 20px"  v-if="!pageloading"></vcl-facebook>
  <v-row v-else justify="center">
    <v-dialog v-model="dialog3" persistent  max-width="390" >

      <v-card>
        <v-card-title style="font-size: 16px;word-break: normal">
         آیا برای خروج از حساب کاربری مطمن هستید؟
        </v-card-title>
        <v-card-text>این عملیات غیر قابل بازگشت میباشد و برای دسترسی دوبار باید لاگین کنید</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  color="green darken-1" text @click="dialog3 = false">
            خیر منصرف شده
          </v-btn>
          <v-btn color="red darken-1" text @click="logoutApp">
            بله مطمنم
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>


</div>

</template>
<script>
  import {mapGetters} from 'vuex';
  import {mapMutations} from 'vuex';
  import axios from "axios";
  import { VclFacebook, VclInstagram } from 'vue-content-loading';

  export default {
    name: 'perfectmoney',
    components:{
      VclFacebook,
      VclInstagram,
      mapGetters,mapMutations},
    data() {
      return {
        activecode:"",
        user:"32157699",
        pass:"Marka1358@@",
        idper:"U37678770",
        vocher:"",
        price:[],
        pricematic:0,
        loading:false,
        btnperfect1:true,
        btnperfect2:false,
        dialog:false,
        dialog2:false,
        dialog3:false,
        usdprice:0,
        sabtkifpool:false,
        pageloading:false,

      }
    },
    mounted() {
      let self = this;
      this.getInfo();


    },
    computed:{
      ...mapGetters(['auth','appToken','baseUrl']),
    },

    methods:{
      ...mapMutations(['logout']),

      sendtodaraee() {
        //aval bayad dar site man darj shavad agar darj shod hala bayad beravad be perfectmoney
        let self = this;
        const config = {
          headers: {
            'content-type': 'multipart/form-data' ,
          }
          }
        let formData = new FormData();
        self.loading=true;
        /*
        AccountID$: نام کاربری یا آی دی ورود به سایت پرفکت مانی
        PassPhrase$: کلمه عبور یا پسورد شما برای ورود به سایت پرفکت مانی
        Payee_Account$: اکانتی که میخواهید مقدار کد ووچر به آن اضافه شود
        ev_number$:شماره کد ووچر که کاربر به ارائه کرده است
        ev_code$: کد فعالسازی ووچر که کاربر ارائه کرده است
         */
        formData.append("ev_number", self.vocher);
        formData.append("ev_code", self.activecode);

        axios.post('https://soccerp.xyz/index.php', formData, config)
            .then(function (r) {
              //console.log(r.data);
              if(r.data.PAYMENT_BATCH_NUM > 0){
                if(r.data.VOUCHER_AMOUNT > 0)
                {
                  let pricebatakhfifi = r.data.VOUCHER_AMOUNT * self.usdprice;
                  pricebatakhfifi=pricebatakhfifi + (pricebatakhfifi * 5 / 100);
                  self.updatedaraee(r.data.VOUCHER_NUM,pricebatakhfifi);
                }
                else
                {
                  self.helperToast({
                    show:true,
                    text:"کد شما مورد قبول نیست با مدیر تماس بگیرید",
                    color:'red'
                  });
                }
                self.loading=false;



              }
              else
              {
                self.helperToast({
                  show:true,
                  text:r.data.ERROR,
                  color:'red'
                });
              }
            });

      },
      preg_match_all(regex, str) {

        //var regex = /<input name='VOUCHER_AMOUNT' type='hidden' value='(.*)'>/;
        var values=str.match(regex);
        //console.log(amount[1]);
        //regex = /<input name='VOUCHER_NUM' type='hidden' value='(.*)'>/;
        //const numbervoucher=str.match(regex);
        //console.log(numbervoucher[1]);
        return values[1];

      },

      updatedaraee(vouchernumber,mablagh){
        let self = this;
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        let formData = new FormData();
        formData.append("token", self.appToken);
        formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
        formData.append("vouchernumber", vouchernumber);
        formData.append("mablagh", mablagh);

        axios.post(this.baseUrl+'/daraeeperfectupdate.aspx', formData, config)
            .then(function (r) {
              if(r.data.data.result=="1"){
                self.helperToast({
                  show:true,
                  text:"مبلغ به دارایی شما اضافه گشت",
                  color:'green'
                });
                self.$router.push({path:'/profile'});
              }
              else
              {
                self.helperToast({
                  show:true,
                  text:r.data.data.message,
                  color:'red'
                });
              }
              self.pageloading=true;
            });

      },
      insertTodaraee(vouchernumber,voucherramz){
        let self = this;
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        self.loading=true;
        let formData = new FormData();
        formData.append("token", self.appToken);
        formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
        formData.append("vouchernumber", vouchernumber);
        formData.append("voucherramz", voucherramz);

        axios.post(this.baseUrl+'/daraeeperfect.aspx', formData, config)
            .then(function (r) {
              if(r.data.data.result=="1"){
                self.getInfo();
                self.sendtodaraee();
                self.helperToast({
                  show:true,
                  text:r.data.data.message,
                  color:'red'
                });

              }
              else
              {
                self.helperToast({
                  show:true,
                  text:r.data.data.message,
                  color:'red'
                });
              }
              self.pageloading=true;
            });

      },
      getInfo(){
        let self = this;
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        let formData = new FormData();
        formData.append("token", self.appToken);
        formData.append("code_shakhs", self.$store.getters.auth.data.user_id);

        axios.post(this.baseUrl+'/crypto.aspx', formData, config)
            .then(function (r) {
              if(r.data.data.result=="1"){

                self.usdprice=r.data.data.usd;

              }
              else
              {
                self.helperToast({
                  show:true,
                  text:r.data.data.message,
                  color:'red'
                });
              }
              self.pageloading=true;
            });

      },
      logoutApp(){
        this.logout();
        this.$router.push({path:'/'})

      },

    },
    watch:{

    }
  }
</script>

<style>
  .header-r{
    text-align: center;
  }
  .header-l p{

  }
  .header-l p span.subtitle{
    font-weight: bold;
    font-size: 13px;
  }

  #gallery {
    margin-top: 10px;
  }
  .fm-preview-img{
    width:  100%;
    height: 100px;
    border-radius: 5px;
    position: relative;
    vertical-align: middle;
    margin-bottom: 5px;
    background: #DDD;
    display: flex;
    align-items: center;
    padding: 10px;
    overflow: hidden;
  }
  .fm-preview-img img{
    max-width: 100px;
    height: 100%;
    max-height: 100px;
    border-radius: 5px;
    margin-left: 5px;
  }
  .text-error{
    color: coral;
    font-weight: bold;
    line-height: 1;
    font-size: 13px;
  }

  .fileBselectRows{
    width: 100%;
    border:dashed 2px #0084ff;
    border-radius: 5px;
    height: 105px;
    overflow: hidden;
    position: relative;
    z-index: 98;
    vertical-align: middle;
    display: inline-block;
    background: #fff;
  }
  .fileBrows input{
    width: 100%;
    height: 100%;
    border:solid 5px;
    opacity: 0;
    cursor: pointer;
    display: block;
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
  }
  .fileBrows span{
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    z-index: 9;
    position: absolute;
    display: block;
    color: #0084ff;
    left: 0;
    top:calc(30% - 20px);
  }
  .fileBrows span i{
    font-size: 30px;
    font-style: normal;
  }

</style>







